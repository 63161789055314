import { useContext, useEffect } from 'react';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { AppContext } from 'store/AppContext';

i18n.use(initReactI18next).init({
    resources: {},
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
});

function I18nUpdater() {
    const { userData } = useContext(AppContext);

    useEffect(() => {
        if (userData?.template?.content) {
            Object.keys(userData.template.content).forEach(lng => {
                const languageCode = lng.toLowerCase();
                i18n.addResources(
                    languageCode,
                    'translation',
                    userData.template.content[lng]
                );
            });
            i18n.changeLanguage(i18n.language.toLowerCase());
        }
    }, [userData]);

    return null;
}

export default I18nUpdater;
