import React from 'react';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import ApiProvider from 'modules/Api/ApiContext';
import I18nUpdater from 'modules/Translation/i18n';
import { AppProvider } from 'store/AppContext';

const Root = React.lazy(() => import('containers/Root'));

ReactDOM.createRoot(document.getElementById('root')).render(
    <AppProvider>
        <ApiProvider>
            <BrowserRouter>
                <I18nUpdater />
                <Root />
            </BrowserRouter>
        </ApiProvider>
    </AppProvider>
);
