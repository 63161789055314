import { template } from "lodash";

export const DEFAULT_BOOTSTRAP_DATA = {
    requestId: null,
    id: null,
    partner_short_id: null,
    wma_display_name: null,
    name: null,
    message_expiry_time: null,
    auth_expiry_time: null,
    resend_otp_interval: null,
    resend_otp_max_limit: null,
    refresh_call_status_interval: null,
    // call_details
    phoneNumber: null,
    call_detail: {},
    // heading
    headingDefaultHeading: null,
    headingDefaultWelcomeMessage: null,
    headingDefault911Str: null,
    // footer
    footerCopyright: null,
    footerTOS: null,
    // template
    template: {
        content: {
            // style
            textColor: '#313131',
            backgroundColor: '#4383C4',
            fontFamily: '"Roboto", sans-serif',
            // language
            en: {},
            es: {}
        }
    },
};

export const DEFAULT_REPLIES_DATA = {
    status: null,
    responses: []
}

export const modifyUserData = (items, setUserData) => {
    const temp = {};

    items.forEach(item => {
        const keyMapping = {
            style: {
                textColor: 'styleTextcolor',
                backgroundColor: 'styleBackgroundColor',
                fontFamily: 'styleFontFamily',
            },
            heading: {
                defaultHeading: 'headingDefaultHeading',
                defaultWelcomeMessage: 'headingDefaultWelcomeMessage',
                headingDefault911Str: 'headingDefault911Str',
            },
            footer: {
                copyright: 'footerCopyright',
                TOS: 'footerTOS',
            },
        };

        const mappings = keyMapping[item.type] || {};

        (item?.items || []).forEach(prop => {
            if (prop.value && mappings[prop.key]) {
                temp[mappings[prop.key]] = prop.value;
            }
        });
    });

    setUserData(temp);
};
