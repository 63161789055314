import React, { createContext, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import {
    DEFAULT_BOOTSTRAP_DATA,
    DEFAULT_REPLIES_DATA,
    modifyUserData,
} from './Config';

const AppContext = createContext();

function AppProvider({ children }) {
    const [userData, setUserData] = useState(DEFAULT_BOOTSTRAP_DATA);
    const [repliesData, setRepliesData] = useState(DEFAULT_REPLIES_DATA);

    const updateUserData = newData => {
        setUserData(prevData => ({ ...prevData, ...newData }));
    };

    const setNewUserData = ({ call_detail, template, partner, ...rest }) => {
        updateUserData({
            phoneNumber: call_detail?.callback_number || '',
            call_detail,
            template,
            ...partner,
            ...rest,
        });

        const templateItems = template?.content?.items || [];
        modifyUserData(templateItems, updateUserData);
    };

    const contextValue = useMemo(
        () => ({
            userData,
            updateUserData,
            setNewUserData,
            repliesData,
            setRepliesData,
        }),
        [userData, repliesData]
    );

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
}

AppProvider.propTypes = {
    children: PropTypes.node,
};

AppProvider.defaultProps = {
    children: null,
};

export { AppProvider, AppContext };
