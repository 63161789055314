export const formatTime = totalSeconds => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const getCookie = key => {
    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
        const [k, v] = cookie.split('=');
        acc[k] = v;
        return acc;
    }, {});
    return cookies[key] || null;
};

export const setCookie = (key, value, maxAgeSeconds) => {
    document.cookie = `${key}=${value}; max-age=${parseInt(maxAgeSeconds, 10)}; path=/; SameSite=Lax`;
};

export const extractParams = pathname => {
    const [rawPId, rawRId] = pathname.split('/').filter(Boolean);

    return {
        partnerId: rawPId?.startsWith('p') ? rawPId : null,
        requestId: rawRId?.startsWith('r') ? rawRId : null,
    };
};

export const addParamsToUrl = (url, params) => {
    const urlObj = new URL(url);
    urlObj.search = new URLSearchParams(params).toString();
    return urlObj.toString();
}

export const convertToLocalPhoneFormat = phoneNumber => {
    const cleanedNumber = phoneNumber.replace(/\+1|\D/g, '');

    if (cleanedNumber.length !== 10) {
        throw new Error(
            'Invalid phone number. Must be 10 digits after country code.'
        );
    }

    const formattedNumber = cleanedNumber.replace(
        /(\d{3})(\d{3})(\d{4})/,
        '$1-$2-$3'
    );
    return formattedNumber;
};
